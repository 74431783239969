<template>
  <!--begin::Quotation Internal Listing-->
  <v-container fluid>
    <v-container>
      <v-row>
        <v-col md="2">
          <v-text-field
            v-model.trim="filter.search"
            v-on:click:clear="clearFilter('search')"
            clearable
            dense
            filled
            flat
            placeholder="Search"
            hide-details
            solo
            color="cyan"
            @keydown.enter="getAllOpportunity"
            @keydown.tab="getAllOpportunity"
          ></v-text-field>
        </v-col>
        <v-col md="3">
          <DateRangePicker
            placeholder="Data Range"
            v-model="dates"
            v-on:update:model-value="getAllOpportunity()"
            v-on:click:clear="clearFilter('dates')"
          ></DateRangePicker>
        </v-col>
      </v-row>
    </v-container>
    <ListingTable :columnCount="6" :rowData="dataRows2">
      <template v-slot:thead>
        <thead>
          <tr>
            <th class="simple-table-th" width="100">Opportunity #</th>
            <th class="simple-table-th" width="200">Opportunity</th>
            <th class="simple-table-th" width="200">Project</th>
            <th class="simple-table-th" width="100">Amount</th>
            <th class="simple-table-th" width="100">Submitted</th>
            <th class="simple-table-th" width="100">Accepted</th>
            <th class="simple-table-th" width="100">Milestones</th>
          </tr>
        </thead>
      </template>
      <template v-slot:tbody>
        <tbody class="custom-border-bottom custom-border-top">
          <template v-if="dataRows2.length > 0">
            <tr
              link
              :key="index"
              v-for="(row, index) in dataRows2"
              v-on:click="
                $router.push(
                  getDefaultRoute('opportunity.detail', {
                    params: { id: row.id },
                  })
                )
              "
              class="alternate-listing-row"
            >
              <td class="simple-table-td">
                <Barcode :barcode="row.barcode"></Barcode>
              </td>
              <td class="simple-table-td" width="200">
                <div class="project-listing-project">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Name: </b> {{ row.opportunity_name }}
                  </p>

                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Start Date: </b>
                    {{ formatDate(row.start_date) }}
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>End Date: </b> {{ formatDate(row.end_date) }}
                  </p>
                </div>
              </td>
              <td class="simple-table-td" width="200">
                <div class="project-listing-project">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Project #: </b>
                    <template v-if="row.project"
                      ><span class="font-weight-500">{{
                        row?.project?.barcode
                      }}</span>
                    </template>
                    <template v-else
                      ><em class="text--secondary">no project </em>
                    </template>
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Project: </b>
                    <template v-if="row.project"
                      ><span class="font-weight-500">{{
                        row.project.name
                      }}</span>
                    </template>
                    <template v-else
                      ><em class="text--secondary">no project </em>
                    </template>
                  </p>
                </div>
              </td>
              <td class="simple-table-td" width="100">
                <div class="project-listing-project">
                  <!-- <p class="m-0 custom-nowrap-ellipsis">
                    <b>Opportunity Value: </b>
                    <template v-if="row && row.project_value"
                      ><span class="font-weight-500">{{
                        formatMoney(row.project_value)
                      }}</span></template
                    >
                    <template v-else
                      ><em class="text--secondary">No Opportunity </em>
                    </template>
                  </p> -->
                  <p class="m-0 custom-nowrap-ellipsis">
                    <!-- <b>Opportunity Budget: </b> -->
                    <template v-if="row && row.budget"
                      ><span class="font-weight-500">{{
                        formatMoney(row.budget)
                      }}</span></template
                    >
                    <template v-else
                      ><em class="text--secondary">No Budget </em>
                    </template>
                  </p>
                </div>
              </td>
              <td class="simple-table-td" width="100">
                <div class="contract-listing-customer">
                  <p
                    class="m-1 mb-1 custom-nowrap-ellipsis-count d-flex align-center"
                  >
                    <v-badge color="cyan" inline :content="4"> </v-badge>
                    <b>Total Opportunity </b>
                  </p>
                  <p
                    class="m-1 mb-1 custom-nowrap-ellipsis-count d-flex align-center"
                  >
                    <v-badge color="green" inline :content="2"> </v-badge>
                    <b>Total Costing </b>
                  </p>
                  <p
                    class="m-1 mb-1 custom-nowrap-ellipsis-count d-flex align-center"
                  >
                    <v-badge color="red" inline :content="2"> </v-badge>
                    <b>Total Quotaion </b>
                  </p>
                </div>
              </td>
              <td class="simple-table-td" width="100">
                <div class="contract-listing-customer">
                  <p
                    class="m-1 mb-1 custom-nowrap-ellipsis-count d-flex align-center"
                  >
                    <v-badge color="green" inline :content="2"> </v-badge>
                    <b>Accepted Quotation </b>
                  </p>
                  <p
                    class="m-1 mb-1 custom-nowrap-ellipsis-count d-flex align-center"
                  >
                    <v-badge color="orange" inline :content="2"> </v-badge>
                    <b>Pending Quotation </b>
                  </p>
                </div>
                <!-- <CustomStatus
                  small
                  :status="row.status"
                  endpoint="quotation/status"
                ></CustomStatus> -->
              </td>
              <td class="simple-table-td" width="100">
                <div class="contract-listing-customer">
                  <p
                    class="m-1 mb-1 custom-nowrap-ellipsis-count d-flex align-center"
                  >
                    <v-badge color="cyan" inline :content="5"> </v-badge>
                    <b>Total Milestones </b>
                  </p>
                  <p
                    class="m-1 mb-1 custom-nowrap-ellipsis-count d-flex align-center"
                  >
                    <v-badge color="green" inline :content="2"> </v-badge>
                    <b>Started Milestones </b>
                  </p>
                  <p
                    class="m-1 mb-1 custom-nowrap-ellipsis-count d-flex align-center"
                  >
                    <v-badge color="red" inline :content="3"> </v-badge>
                    <b>Completed Milestones </b>
                  </p>
                </div>
              </td>
            </tr>
          </template>
          <tr v-else>
            <td colspan="7">
              <p
                class="m-0 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image"
                />
                Uhh... There are no opportunity at the moment.
              </p>
            </td>
          </tr>
        </tbody>
      </template>
    </ListingTable>
    <ListingFooter
      :dataLoading="opportunityLoading"
      :showingFrom="showingFrom"
      :showingTo="showingTo"
      :totalRows="totalRows"
      :currentPage="currentPage"
      :totalPages="totalPages"
    ></ListingFooter>
    <template v-if="customerPersonDialog">
      <CustomerPersonDialog
        :customerPersonDialog="customerPersonDialog"
        :customer="customer"
        v-on:closeDialog="closeDialog"
        v-on:resetAll="resetAll"
        v-on:selectCustomerPerson="selectCustomerPerson"
      ></CustomerPersonDialog>
    </template>
  </v-container>
  <!--end::Quotation Internal Listing-->
</template>

<script>
import moment from "moment-timezone";
import { QUERY } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import CustomerPersonDialog from "@/view/pages/partials/Select-Customer-Person.vue";
//import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import JwtService from "@/core/services/jwt.service";
import DateRangePicker from "@/view/components/DateRangePicker";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin],
  name: "quotation-internal-list",
  data() {
    return {
      parent: 0,
      opportunityLoading: false,
      customer: 0,
      userDetails: {},
      customerPerson: 0,
      customerBilling: 0,
      currentPage: 1,
      status: "all",
      dates: [],
      statusList: [],
      dataRows2: [],
      totalPages: 0,
      totalRows: 0,
      rowsOffset: 0,
      dataLoading: true,
      customerPersonDialog: false,
      momentObject: moment,
      customerList: [],
      filter: {
        status: "all",
        search: null,
      },
      timeout: null,
      timeoutLimit: 500,
    };
  },
  props: {
    detail: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
    type: {
      type: String,
      required: true,
      default: "product",
    },
    typeId: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    showingFrom() {
      return this.lodash.isEmpty(this.dataRows2) === false
        ? this.rowsOffset + 1
        : 0;
    },
    showingTo() {
      return this.rowsOffset + (this.dataRows2 ? this.dataRows2.length : 0);
    },
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.parent = this.lodash.toSafeInteger(param.id);
        if (this.parent > 0) {
          if (this.lodash.isEmpty(param.customer) === false) {
            this.customer = this.lodash.toSafeInteger(param.customer.customer);
            if (this.lodash.isEmpty(param.customer_billing) === false) {
              this.customerBilling = param.customer_billing.id;
            }
          }
          /* this.getAllQuotations(); */
        }
      },
    },
  },
  components: {
    Barcode,
    CustomerPersonDialog,
    ListingTable,
    //CustomStatus,
    ListingFooter,

    DateRangePicker,
  },
  methods: {
    getOpportunity() {
      const data = {
        current_page: this.currentPage,
        search: this.search,
        status: this.status,
        entity: 0,
        type: this.type,
        type_id: this.typeId,
      };

      if (this.opportunityLoading) {
        return false;
      }

      this.opportunityLoading = true;

      this.$store
        .dispatch(QUERY, { url: "all/opportunity", data: data })
        .then(({ data }) => {
          this.totalPages = Math.ceil(data.total_rows / data.per_page);
          this.currentPage = data.current_page;
          this.dataRows2 = data.rows;
          this.totalRows = data.total_rows;
          this.rowsOffset = data.offset;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.opportunityLoading = false;
        });
    },
    getValidityDate(data) {
      if (data.added_at) {
        let date = moment(data.added_at, "YYYY-MM-DD").format("YYYY-MM-DD");
        let validity = moment(date, "YYYY-MM-DD")
          .add(data.validity_till, "days")
          .format("YYYY-MM-DD");
        return this.formatDate(validity);
      }
    },
    resetAll() {
      this.customerPerson = 0;
      this.customerBilling = 0;
      this.closeDialog();
    },
    closeDialog() {
      this.customerPersonDialog = false;
    },
    selectCustomerPerson(param) {
      this.customerPerson = param;
      this.closeDialog();
      this.checkQuotation();
      if (!this.customerBilling || this.customerBilling <= 0) {
        this.resetAll();
      }
    },
    selectCustomerProperty(param) {
      this.customerBilling = param;
      this.closeDialog();
      this.checkQuotation();
    },
    checkQuotation() {
      const _this = this;
      if (
        _this.customer > 0 &&
        _this.customerPerson > 0 &&
        _this.customerBilling > 0
      ) {
        let queryObject = new Object({
          customer: _this.customer,
          contact_person: _this.customerPerson,
          billing: _this.customerBilling,
        });

        queryObject[_this.type] = _this.parent;

        _this.$router.push(
          _this.getDefaultRoute("quotation.create", {
            query: queryObject,
          })
        );
      }
    },
    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.filter.search = null;
          break;
        case "dates":
          _this.dates = [];
          break;
      }
      _this.getAllOpportunity();
    },
    getAllOpportunity() {
      const _this = this;

      let filter = {
        per_page: 10,
        current_page: 1,
        status: _this.filter.status || "all",
        search: _this.filter.search || undefined,
        dates: _this.dates || undefined,
        type: _this.type,
        type_id: _this.parent,
      };
      if (_this.lodash.isEmpty(_this.dates) === false) {
        if (moment(_this.dates[0]).isAfter(_this.dates[1])) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("End Date must be after Start Date.")
          );
          return false;
        }
      } else {
        filter.dates = undefined;
      }

      clearTimeout(_this.timeout);
      _this.dataRows = [];
      _this.dataLoading = true;
      _this.timeout = setTimeout(function () {
        _this.$store
          .dispatch(QUERY, {
            url: "all/opportunity",
            data: filter,
          })
          .then(({ data }) => {
            _this.dataRows2 = data.rows;
            _this.statusList = data.status_list;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.dataLoading = false;
          });
      }, _this.timeoutLimit);
    },
  },
  mounted() {
    this.getOpportunity();
    this.userDetails = JwtService.currentUser();
  },
};
</script>
